import { createAsyncThunk } from "@reduxjs/toolkit";
import { withoutAuthAxios,authAxios } from "../../Config/axios";
import { Dispatch } from 'redux';
import {store} from "../store";
import {
  loginSuccess,
  loginFailure,
  logout,
  changePasswordFailure,
  balanceSuccess,
  selfDownline
} from "./authSlice";
import API from "../../api";
import { LoginPayload, ChangePasswordPayload, SelfDownlineinfoPayload } from "../../model/auth"; // Correct the typo 'modal' to 'model' if needed
import { AxiosResponse } from "axios";

export const loginUser = async (payload: any, { dispatch }: { dispatch: Dispatch }): Promise<any>  => {
  return new Promise(async (resolve, reject) => {
      let url = 'agent/v1/auth/agentLogin';
      await withoutAuthAxios().post(url, payload)
          .then(
              response => {
                  if (response.status === 201) {
                    dispatch(loginSuccess(response?.data?.data));
                    resolve(response.data);
                  }
                  else{
                     reject(response.data);
                  }
              },
              error => {
                unAuthenticateToken(error);
                reject(error.response.data.message);
              }
          )
          .catch(
              error => {
                dispatch(loginFailure(error.message));
                  reject(error.message);
              }
          )
  })
}

export const changePassword = createAsyncThunk(
  "auth/changePassword",
  async (payload: ChangePasswordPayload, { dispatch }) => {
    try {
      const response = await authAxios().post<any>("/agent/v1/profile/changepassword", payload);
      if (response) {
        
        //window.location.replace('/');
        window.location.reload();
        dispatch(logout());
      }
    } catch (error: any) {
      dispatch(changePasswordFailure(error?.response?.data?.error_message));
    }
  }
);

export const getUserBalance = createAsyncThunk(
  "auth/getBalance",
  async (_, { dispatch }) => {
    try {
      const response = await API.get<any>("/client/v1/balance/getbalance");
      if (response) {
        dispatch(balanceSuccess(response?.data?.data));
      }
    } catch (error: any) {
      
      //window.location.replace('/');
      window.location.reload();
      dispatch(logout());
    }
  }
);

export const getProfileData = (payload: any): Promise<AxiosResponse> => {
  return API.post("/client/getprofile", payload);
};

export const updateProfileData = (payload: any): Promise<AxiosResponse> => {
  return API.post("/client/updateprofile", payload);
};

// export const getActivityLogData = (payload: any): Promise<AxiosResponse> => {
//   return API.post("/client/activitylog", payload);
// };
export const getActivityLogData = (page: number): Promise<AxiosResponse> => {
  // Adding pageNumber to the payload
  const updatedPayload = {
    page: page,
  };
  // Making the API call with the updated payload
  return API.get("/client/v1/reporting/activitylog", {
    params: updatedPayload,
  });
};

export const getcurrentBestData = (payload: any): Promise<AxiosResponse> => {
  return API.post("/client/currentexchangebet", payload);
};

export const getBookmakerBestData = (payload: any): Promise<AxiosResponse> => {
  return API.post("/client/bookmakerbethistory", payload);
};

export const getFancyBestData = (payload: any): Promise<AxiosResponse> => {
  return API.post("/client/currentfancybet", payload);
};

export const getSportBetsData = (payload: any): Promise<AxiosResponse> => {
  return API.post("/client/currentsportsbookbet", payload);
};

// export const getBalanceOverview = (payload: any): Promise<AxiosResponse> => {
//   return API.post("/client/balanceoverview", payload);
// };
export const getBalanceOverview = (page: number): Promise<AxiosResponse> => {
  const updatedPayload = {
    page: page,
  };
  return API.get("/client/v1/balance/balanceoverview", {
    params: updatedPayload,
  });
};

export const getAccountStatementData = (
  page: number
): Promise<AxiosResponse> => {
  const updatedPayload = {
    page: page,
  };
  return API.get("/client/v1/reporting/accountstatement", {
    params: updatedPayload,
  });
};

export const getProfitLossData = (payload: any): Promise<AxiosResponse> => {
  let endpoint = "";
  switch (payload?.tab) {
    case 0:
      endpoint = "/client/exchangeprofitloss";
      break;
    case 1:
      endpoint = "/client/fancyprofitloss";
      break;
    case 2:
      endpoint = "/client/sportbookprofitloss";
      break;
    case 3:
      endpoint = "/client/bookmakerprofitloss";
      break;
    default:
      endpoint = "/client/exchangeprofitloss";
      break;
  }
  const { tab, ...payloadWithoutTab } = payload;
  return API.post(endpoint, payloadWithoutTab);
};

export const handleLogout = createAsyncThunk(
  "auth/logout",
  async (_, { dispatch }) => {
    try {
      const response = await authAxios().patch<any>("/agent/v1/auth/agentlogout");
      if (response) {
        
         //window.location.replace('/');
        window.location.reload();
        dispatch(logout());
      }
    } catch (error: any) {}
  }
);

export const getSelfDownlineInfo = async (payload: any, { dispatch }: { dispatch: Dispatch }): Promise<any>  => {
  return new Promise(async (resolve, reject) => {
      let url = 'agent/v1/downline/getselfdownlineinfo';
      await authAxios().post(url, payload)
          .then(
              response => {
                  if (response.status === 201) {
                     dispatch(selfDownline(response?.data?.data));
                     resolve(response.data);
                  }
                  else{
                      reject(response.data);
                  }
              },
              error => {
                unAuthenticateToken(error);
                reject(error.response.data.message);
              }
          )
          .catch(
              error => {
                  console.log("errorrrr", error.message);
                  reject(error.message);
              }
          )
  })
}


export const GetCreditRefLogs = async (payload: any): Promise<any>  => {
  return new Promise(async (resolve, reject) => {
      let url = 'agent/v1/downline/getCreditRefLogs';
      await authAxios().post(url, payload)
          .then(
              response => {
                  if ([200,201].includes(response.status)) {
                     resolve(response.data);
                  }
                  else{
                      reject(response.data);
                  }
              },
              error => {
                unAuthenticateToken(error);
                reject(error.response.data.message);
              }
          )
          .catch(
              error => {
                  console.log("errorrrr", error.message);
                  reject(error.message);
              }
          )
  })
}

export const GetAnnouncements = async (): Promise<any>  => {
  return new Promise(async (resolve, reject) => {
      let url = 'agent/v1/downline/getAnnouncements';
      await authAxios().get(url)
          .then(
              response => {
                  if ([200,201].includes(response.status)) {
                     resolve(response.data);
                  }
                  else{
                      reject(response.data);
                  }
              },
              error => {
                unAuthenticateToken(error);
                reject(error.response.data.message);
              }
          )
          .catch(
              error => {
                  console.log("errorrrr", error.message);
                  reject(error.message);
              }
          )
  })
}

export const GetAccountBalance = async (): Promise<any>  => {
  return new Promise(async (resolve, reject) => {
      let url = 'agent/v1/profile/getBalance';
      await authAxios().get(url)
          .then(
              response => {
                  if ([200,201].includes(response.status)) {
                     resolve(response.data);
                  }
                  else{
                      reject(response.data);
                  }
              },
              error => {
                unAuthenticateToken(error);
                reject(error.response.data.message);
              }
          )
          .catch(
              error => {
                  console.log("errorrrr", error.message);
                  reject(error.message);
              }
          )
  })
}

export const getDownlineInfo = async (payload: any): Promise<any>  => {
  return new Promise(async (resolve, reject) => {
      let url = 'agent/v1/downline/downlinelistinfo';
      await authAxios().post(url, payload)
          .then(
              response => {
                  if (response.status === 201) {
                     resolve(response.data);
                  }
                  else{
                      reject(response.data);
                  }
              },
              error => {
                unAuthenticateToken(error);
                reject(error.response.data.message);
              }
          )
          .catch(
              error => {
                  console.log("errorrrr", error.message);
                  reject(error.message);
              }
          )
  })
}

export const updatecreditref = async (payload: any): Promise<any>  => {
  return new Promise(async (resolve, reject) => {
      let url = 'agent/v1/downline/updatecreditref';
      await authAxios().post(url, payload)
          .then(
              response => {
                  if (response.status === 201) {
                     resolve(response.data);
                  }
                  else{
                      reject(response.data);
                  }
              },
              error => {
                unAuthenticateToken(error);
                reject(error.response.data.message);
              }
          )
          .catch(
              error => {
                  console.log("errorrrr", error.message);
                  reject(error.message);
              }
          )
  })
}

export const updateDownlineStatus = async (payload: any): Promise<any>  => {
  return new Promise(async (resolve, reject) => {
      let url = 'agent/v1/downline/setuserblocked';
      await authAxios().post(url, payload)
          .then(
              response => {
                  if (response.status === 201) {
                     resolve(response.data);
                  }
                  else{
                      reject(response.data);
                  }
              },
              error => {
                unAuthenticateToken(error);
                reject(error.response.data.message);
              }
          )
          .catch(
              error => {
                  console.log("errorrrr", error.message);
                  reject(error.message);
              }
          )
  })
}

export const GetWhiteLevel = async (): Promise<any>  => {
  return new Promise(async (resolve, reject) => {
      let url = 'agent/v1/downline/getWhiteLevel';
      await authAxios().get(url)
          .then(
              response => {
                  if ([200,201].includes(response.status)) {
                     resolve(response.data);
                  }
                  else{
                      reject(response.data);
                  }
              },
              error => {
                unAuthenticateToken(error);
                reject(error.response.data);
              }
          )
          .catch(
              error => {
                  console.log("errorrrr", error.message);
                  reject(error.message);
              }
          )
  })
}

export const addDownlineUser = async (payload: any): Promise<any>  => {
  return new Promise(async (resolve, reject) => {
      let url = 'agent/v1/downline/createuser';
      await authAxios().post(url, payload)
          .then(
              response => {
                  if (response.status === 201) {
                     resolve(response.data);
                  }
                  else{
                      reject(response.data);
                  }
              },
              error => {
                if([400].includes(error.response.status)){
                  reject(error.response.data.message)
                }
                else{
                  if(error.response.data.error){
                    if(Array.isArray(error.response.data.error)){
                      reject(Object.values(error.response.data.error[0])[0])
                    }
                    else{
                      reject(error.response.data.error)
                    }
                  }
                }
              }
          )
          .catch(
              error => {
                  console.log("errorrrr", error.message);
                  reject(error.message);
              }
          )
  })
}

export const CheckExistingUser = async (payload: any): Promise<any>  => {
  return new Promise(async (resolve, reject) => {
      let url = 'agent/v1/downline/checkExistingUser';
      await authAxios().post(url, payload)
          .then(
              response => {
                  if ([200,201].includes(response.status)) {
                     resolve(response.data);
                  }
                  else{
                      reject(response.data);
                  }
              },
              error => {
                unAuthenticateToken(error);
                reject(error.response.data.message);
              }
          )
          .catch(
              error => {
                  console.log("errorrrr", error.response);
                  reject(error.message);
              }
          )
  })
}

export const getProfile = async (payload: any): Promise<any>  => {
  return new Promise(async (resolve, reject) => {
      let url = 'agent/v1/profile/getprofile';
      await authAxios().post(url, payload)
          .then(
              response => {
                  if ([200,201].includes(response.status)) {
                     resolve(response.data);
                  }
                  else{
                      reject(response.data);
                  }
              },
              error => {
                unAuthenticateToken(error);
                reject(error.response.data.message);
              }
          )
          .catch(
              error => {
                  console.log("errorrrr", error.message);
                  reject(error.message);
              }
          )
  })
}

export const updatePassword = async (payload: any): Promise<any>  => {
  return new Promise(async (resolve, reject) => {
      let url = payload.LevelNumber>0?'agent/v1/profile/changedownpassword':'agent/v1/profile/changepassword';
      await authAxios().post(url, payload)
          .then(
              response => {
                  if (response.status === 201) {
                     resolve(response.data);
                  }
                  else{
                      reject(response.data);
                  }
              },
              error => {
                unAuthenticateToken(error);
                reject(error.response.data.message);
              }
          )
          .catch(
              error => {
                  console.log("errorrrr", error.message);
                  reject(error.message);
              }
          )
  })
}
export const updateprofile = async (payload: any): Promise<any>  => {
  return new Promise(async (resolve, reject) => {
      let url = 'agent/v1/profile/updateprofile';
      await authAxios().post(url, payload)
          .then(
              response => {
                  if (response.status === 201) {
                     resolve(response.data);
                  }
                  else{
                      reject(response.data);
                  }
              },
              error => {
                unAuthenticateToken(error);
                reject(error.response.data.message);
              }
          )
          .catch(
              error => {
                  console.log("errorrrr", error.message);
                  reject(error.message);
              }
          )
  })
}

export const GetActivitylog = async (payload: any): Promise<any>  => {
  return new Promise(async (resolve, reject) => {
      let url = 'agent/v1/profile/activitylog';
      await authAxios().post(url, payload)
          .then(
              response => {
                  if ([200,201].includes(response.status)) {
                     resolve(response.data);
                  }
                  else{
                      reject(response.data);
                  }
              },
              error => {
                unAuthenticateToken(error);
                reject(error.response.data.message);
              }
          )
          .catch(
              error => {
                  console.log("errorrrr", error.message);
                  reject(error.message);
              }
          )
  })
}

export const GetAccountStatement = async (payload: any): Promise<any>  => {
  return new Promise(async (resolve, reject) => {
      let url = 'agent/v1/profile/accountstatement';
      await authAxios().post(url, payload)
          .then(
              response => {
                  if ([200,201].includes(response.status)) {
                     resolve(response.data);
                  }
                  else{
                      reject(response.data);
                  }
              },
              error => {
                reject(error.response.data);
              }
          )
          .catch(
              error => {
                  console.log("errorrrr", error.message);
                  reject(error.message);
              }
          )
  })
}

export const GetBettingHistory = async (payload: any): Promise<any>  => {
  return new Promise(async (resolve, reject) => {
      let url = 'agent/v1/profile/getBettingHistory';
      await authAxios().post(url, payload)
          .then(
              response => {
                  if ([200,201].includes(response.status)) {
                     resolve(response.data);
                  }
                  else{
                      reject(response.data);
                  }
              },
              error => {
                reject(error.response.data);
              }
          )
          .catch(
              error => {
                  console.log("errorrrr", error.message);
                  reject(error.message);
              }
          )
  })
}

export const GetBetProfitLoss = async (payload: any): Promise<any>  => {
  return new Promise(async (resolve, reject) => {
      let url = 'agent/v1/profile/getBetProfitLoss';
      await authAxios().post(url, payload)
          .then(
              response => {
                  if ([200,201].includes(response.status)) {
                     resolve(response.data);
                  }
                  else{
                      reject(response.data);
                  }
              },
              error => {
                reject(error.response.data);
              }
          )
          .catch(
              error => {
                  console.log("errorrrr", error.message);
                  reject(error.message);
              }
          )
  })
}

export const GetUserBalanceOverView = async (payload: any): Promise<any>  => {
  return new Promise(async (resolve, reject) => {
      let url = 'agent/v1/profile/getBalanceOverview';
      await authAxios().post(url, payload)
          .then(
              response => {
                  if ([200,201].includes(response.status)) {
                     resolve(response.data);
                  }
                  else{
                      reject(response.data);
                  }
              },
              error => {
                unAuthenticateToken(error);
                reject(error.response.data);
              }
          )
          .catch(
              error => {
                  console.log("errorrrr", error.message);
                  reject(error.message);
              }
          )
  })
}

export const GetCasinoBetProfitLoss = async (payload: any): Promise<any>  => {
  return new Promise(async (resolve, reject) => {
      let url = 'agent/v1/Profile/getCasinoPL';
      await authAxios().post(url, payload)
          .then(
              response => {
                  if ([200,201].includes(response.status)) {
                     resolve(response.data);
                  }
                  else{
                      reject(response.data);
                  }
              },
              error => {
                reject(error.response.data);
              }
          )
          .catch(
              error => {
                  console.log("errorrrr", error.message);
                  reject(error.message);
              }
          )
  })
}
export const GetCasinoBetProfitLossDetails = async (payload: any): Promise<any>  => {
  return new Promise(async (resolve, reject) => {
      let url = 'agent/v1/Profile/getCasinoPLDetails';
      await authAxios().post(url, payload)
          .then(
              response => {
                  if ([200,201].includes(response.status)) {
                     resolve(response.data);
                  }
                  else{
                      reject(response.data);
                  }
              },
              error => {
                reject(error.response.data);
              }
          )
          .catch(
              error => {
                  console.log("errorrrr", error.message);
                  reject(error.message);
              }
          )
  })
}

export const GetBetPLDetails = async (payload: any): Promise<any>  => {
  return new Promise(async (resolve, reject) => {
      let url = 'agent/v1/profile/getBetPLDetails';
      await authAxios().post(url, payload)
          .then(
              response => {
                  if ([200,201].includes(response.status)) {
                     resolve(response.data);
                  }
                  else{
                      reject(response.data);
                  }
              },
              error => {
                reject(error.response.data);
              }
          )
          .catch(
              error => {
                  console.log("errorrrr", error.message);
                  reject(error.message);
              }
          )
  })
}

export const GetPasswordDetails = async (payload: any): Promise<any>  => {
  return new Promise(async (resolve, reject) => {
      let url = 'agent/v1/dashboard/getAbsPassword';
      await authAxios().post(url, payload)
          .then(
              response => {
                  if ([200,201].includes(response.status)) {
                     resolve(response.data);
                  }
                  else{
                      reject(response.data);
                  }
              },
              error => {
                reject(error.response.data);
              }
          )
          .catch(
              error => {
                  console.log("errorrrr", error.message);
                  reject(error.message);
              }
          )
  })
}

export const banking = async (payload: any): Promise<any>  => {
  return new Promise(async (resolve, reject) => {
      let url = 'agent/v1/banking/banking';
      await authAxios().post(url, payload)
          .then(
              response => {
                  if ([200,201].includes(response.status)) {
                     resolve(response.data);
                  }
                  else{
                      reject(response.data);
                  }
              },
              error => {
                reject(error.response.data.message);
                unAuthenticateToken(error);
                
              }
          )
          .catch(
              error => {
                  console.log("errorrrr", error.message);
                  reject(error.message);
              }
          )
  })
}

export const GetBankingLog = async (payload: any): Promise<any>  => {
  return new Promise(async (resolve, reject) => {
      let url = 'agent/v1/banking/getBankingLog';
      await authAxios().post(url, payload)
          .then(
              response => {
                  if ([200,201].includes(response.status)) {
                     resolve(response.data);
                  }
                  else{
                      reject(response.data);
                  }
              },
              error => {
                unAuthenticateToken(error);
                reject(error.response.data);
              }
          )
          .catch(
              error => {
                  console.log("errorrrr", error.message);
                  reject(error.message);
              }
          )
  })
}

export const GetBetList = async (payload: any): Promise<any>  => {
  return new Promise(async (resolve, reject) => {
      let url = 'agent/v1/betlist/getBetlist';
      await authAxios().post(url, payload)
          .then(
              response => {
                  if ([200,201].includes(response.status)) {
                     resolve(response.data);
                  }
                  else{
                      reject(response.data);
                  }
              },
              error => {
                unAuthenticateToken(error);
                reject(error.response.data);
              }
          )
          .catch(
              error => {
                  console.log("errorrrr", error.message);
                  reject(error.message);
              }
          )
  })
}

export const GetLiveBetList = async (payload: any): Promise<any>  => {
  return new Promise(async (resolve, reject) => {
      let url = 'agent/v1/betlist/getBetlistLive';
      await authAxios().post(url, payload)
          .then(
              response => {
                  if ([200,201].includes(response.status)) {
                     resolve(response.data);
                  }
                  else{
                      reject(response.data);
                  }
              },
              error => {
                unAuthenticateToken(error);
                reject(error.response.data);
              }
          )
          .catch(
              error => {
                  console.log("errorrrr", error.message);
                  reject(error.message);
              }
          )
  })
}

export const AlertBet = async (payload: any): Promise<any>  => {
  return new Promise(async (resolve, reject) => {
      let url = 'agent/v1/betlist/alertBet';
      await authAxios().post(url, payload)
          .then(
              response => {
                  if ([200,201].includes(response.status)) {
                     resolve(response.data);
                  }
                  else{
                      reject(response.data);
                  }
              },
              error => {
                unAuthenticateToken(error);
                reject(error.response.data);
              }
          )
          .catch(
              error => {
                  console.log("errorrrr", error.message);
                  reject(error.message);
              }
          )
  })
}

export const GetReportDownline = async (payload: any): Promise<any>  => {
  return new Promise(async (resolve, reject) => {
      let url = 'agent/v1/report/reportDownline';
      await authAxios().post(url, payload)
          .then(
              response => {
                  if ([200,201].includes(response.status)) {
                     resolve(response.data);
                  }
                  else{
                      reject(response.data);
                  }
              },
              error => {
                unAuthenticateToken(error);
                reject(error.response.data);
              }
          )
          .catch(
              error => {
                  console.log("errorrrr", error.message);
                  reject(error.message);
              }
          )
  })
}

export const GetReportMarket = async (payload: any): Promise<any>  => {
  return new Promise(async (resolve, reject) => {
      let url = 'agent/v1/report/reportMarket';
      await authAxios().post(url, payload)
          .then(
              response => {
                  if ([200,201].includes(response.status)) {
                     resolve(response.data);
                  }
                  else{
                      reject(response.data);
                  }
              },
              error => {
                unAuthenticateToken(error);
                reject(error.response.data);
              }
          )
          .catch(
              error => {
                  console.log("errorrrr", error.message);
                  reject(error.message);
              }
          )
  })
}

export const GetReportMarketDetails = async (payload: any): Promise<any>  => {
  return new Promise(async (resolve, reject) => {
      let url = 'agent/v1/report/reportMarketDetails';
      await authAxios().post(url, payload)
          .then(
              response => {
                  if ([200,201].includes(response.status)) {
                     resolve(response.data);
                  }
                  else{
                      reject(response.data);
                  }
              },
              error => {
                unAuthenticateToken(error);
                reject(error.response.data);
              }
          )
          .catch(
              error => {
                  console.log("errorrrr", error.message);
                  reject(error.message);
              }
          )
  })
}

export const GetTopTenClients = async (payload: any): Promise<any>  => {
  return new Promise(async (resolve, reject) => {
      let url = (payload.searchBy==='Exposure')?'agent/v1/risk/getTopExposure':'agent/v1/risk/getTopAmount';
      await authAxios().post(url, payload)
          .then(
              response => {
                  if ([200,201].includes(response.status)) {
                     resolve(response.data);
                  }
                  else{
                      reject(response.data);
                  }
              },
              error => {
                unAuthenticateToken(error);
                reject(error.response.data);
              }
          )
          .catch(
              error => {
                  console.log("errorrrr", error.message);
                  reject(error.message);
              }
          )
  })
}

export const GetRiskHorseAndHoundEvents = async (): Promise<any>  => {
  return new Promise(async (resolve, reject) => {
      let url = 'agent/v1/risk/getRiskHorseAndHoundEvents';
      await authAxios().get(url)
          .then(
              response => {
                  if ([200,201].includes(response.status)) {
                     resolve(response.data);
                  }
                  else{
                      reject(response.data);
                  }
              },
              error => {
                unAuthenticateToken(error);
                reject(error.response.data);
              }
          )
          .catch(
              error => {
                  console.log("errorrrr", error.message);
                  reject(error.message);
              }
          )
  })
}

export const GetRiskEventList = async (): Promise<any>  => {
  return new Promise(async (resolve, reject) => {
      let url = 'agent/v1/risk/getRiskEventList';
      await authAxios().get(url)
          .then(
              response => {
                  if ([200,201].includes(response.status)) {
                     resolve(response.data);
                  }
                  else{
                      reject(response.data);
                  }
              },
              error => {
                unAuthenticateToken(error);
                reject(error.response.data);
              }
          )
          .catch(
              error => {
                  console.log("errorrrr", error.message);
                  reject(error.message);
              }
          )
  })
}

export const GetRiskFancyList = async (): Promise<any>  => {
  return new Promise(async (resolve, reject) => {
      let url = 'agent/v1/risk/getRiskFancyList';
      await authAxios().get(url)
          .then(
              response => {
                  if ([200,201].includes(response.status)) {
                     resolve(response.data);
                  }
                  else{
                      reject(response.data);
                  }
              },
              error => {
                unAuthenticateToken(error);
                reject(error.response.data);
              }
          )
          .catch(
              error => {
                  console.log("errorrrr", error.message);
                  reject(error.message);
              }
          )
  })
}

export const GetRiskEventDownline = async (payload: any): Promise<any>  => {
  return new Promise(async (resolve, reject) => {
      let url = 'agent/v1/risk/getRiskEventDownline';
      await authAxios().post(url, payload)
          .then(
              response => {
                  if ([200,201].includes(response.status)) {
                     resolve(response.data);
                  }
                  else{
                      reject(response.data);
                  }
              },
              error => {
                unAuthenticateToken(error);
                reject(error.response.data);
              }
          )
          .catch(
              error => {
                  console.log("errorrrr", error.message);
                  reject(error.message);
              }
          )
  })
}

export const getNumber = (value: any) => {
  if(value && !['-',null].includes(value)){
    let parsedValue = parseFloat(value);
    let newValue = parsedValue.toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
    let negariveValue  = Math.abs(parsedValue).toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
    return (parsedValue >= 0) ? newValue : `<span class="red">(${negariveValue})</span>`;
  }
  else{
    return (value)?value:'0.00';
  }
}

export const formatDateOption = (dateString: string, option:string='') => {
  // Split the date string by "-"
  let dateIs = getDateOnly(dateString);
  const parts = dateIs.split("-");

  // Extract day, month, and year from the parts array
  const day = parseInt(parts[2], 10);
  const month = parseInt(parts[1], 10);
  const year = parseInt(parts[0], 10);

  // Create a new Date object using the extracted values
  const date = new Date(year, month - 1, day); // Month in Date object is 0-based

  // Format the date in the desired format
  
  let formatOption = {};
  switch(option){
    case 'year':{
      formatOption = {
        year: "numeric",
        timeZone: "UTC",
      }
    } break;
    case 'month':{
      formatOption = {
        month: "long",
        timeZone: "UTC",
      }
    } break;
    case 'day':{
      formatOption = {
        day: "numeric",
        timeZone: "UTC",
      }
    } break;
    case 'year-month-day':{
      formatOption = {
        year: "numeric",
        month: "numeric",
        day: "numeric"
      }
    } break;
    default:{
      formatOption = {
        year: "numeric",
        month: "long",
        day: "numeric"
      }
    }

  }
  const formattedDate = date.toLocaleDateString("en-US", formatOption);

  return formattedDate;
};

export const toCamelCase = (str:string) => {
  return str.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');
}

export const formatCaledarDate = (dateString: string) => {
  const date = new Date(dateString);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  const formattedDate = `${year}-${month}-${day}`;

  return formattedDate;
};

export const formatDate = (dateString: string) => {
  const options: any = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: false,
    // timeZone: "IST", // Adjust the time zone as needed
  };

  const date = new Date(dateString);
  return date
    .toLocaleString("en-GB", options)
    .replace(/(\d+)\/(\d+)\/(\d+),?/, "$3-$2-$1");
};

export const getDateOnly = (dateString: string) => {
  const options: any = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit"
  };

  const date = new Date(dateString);
  return date
    .toLocaleString("en-US", options)
    .replace(/(\d+)\/(\d+)\/(\d+),?/, "$3-$1-$2");
};

export const getDateDifference = (date1: string, date2: string)=>{
    var startDate = new Date(date1);
    var endDate = new Date(date2);
    var timeDiff = Math.abs(endDate.getTime() - startDate.getTime());
    var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
    return diffDays;
}

export const generateRandomCode = ()=>{
  return Math.floor(1000 + Math.random() * 9000).toString();
}

export const unAuthenticateToken = (error:any)=>{
  if(error.response.status===403 || error.response.data.message==='failed to authenticate token'){
    
    //window.location.replace('/');
    window.location.reload();
    store.dispatch(logout());
  }
}

export const GetRiskEventRunnerDetails = async (payload: any): Promise<any>  => {
  return new Promise(async (resolve, reject) => {
      let url = 'agent/v1/risk/getRiskEventRunnerDetails';
      await authAxios().post(url, payload)
          .then(
              response => {
                  if ([200,201].includes(response.status)) {
                     resolve(response.data);
                  }
                  else{
                      reject(response.data);
                  }
              },
              error => {
                unAuthenticateToken(error);
                reject(error.response.data);
              }
          )
          .catch(
              error => {
                  console.log("errorrrr", error.message);
                  reject(error.message);
              }
          )
  })
}

export const GetRiskEventStakeDetails = async (payload: any): Promise<any>  => {
  return new Promise(async (resolve, reject) => {
      let url = 'agent/v1/risk/getRiskEventStakeDetails';
      await authAxios().post(url, payload)
          .then(
              response => {
                  if ([200,201].includes(response.status)) {
                     resolve(response.data);
                  }
                  else{
                      reject(response.data);
                  }
              },
              error => {
                unAuthenticateToken(error);
                reject(error.response.data);
              }
          )
          .catch(
              error => {
                  console.log("errorrrr", error.message);
                  reject(error.message);
              }
          )
  })
}

export  const validatePassword = (password: string, username?: string): string => {
  const hasWhitespace = /\s/.test(password);
  const isSameAsUsername = password === username;
  const isValidLength = password.length >= 4 && password.length <= 15;
  const hasUppercase = /[A-Z]/.test(password);
  const hasLowercase = /[a-z]/.test(password);
  const hasNumber = /\d/.test(password);
  // const hasSpecialChar = /[^a-zA-Z0-9]/.test(password);

  if (hasWhitespace) {
    return 'Password must not contain whitespace';
  }
  if (isSameAsUsername) {
    return 'Password cannot be the same as username';
  }
  if (!isValidLength) {
    return 'Password must be between 4 to 15 characters';
  }
  // if (!hasUppercase) {
  //   return 'Password must contain at least one capital letter';
  // }
  // if (!hasLowercase) {
  //   return 'Password must contain at least one small letter';
  // }
  if (!hasNumber) {
    return 'Password must contain at least one number';
  }
  // if (hasSpecialChar) {
  //   return 'Password must not contain any special characters';
  // }
  return '';
};